import axios from "axios";
import Api from "./Api";
import { app } from "@/main.js";

class MaximizerAbApi extends Api {
  constructor() {
    super("address book entry", "maximizer-ab");
  }

  async search(filters) {
    let list = [];
    const errorText = `Could not fetch ${this.model} list`;
    try {
      let url = `/${this.endpoint}/search?firstName=${filters.firstName}&lastName=${filters.lastName}`;
      list = await axios.get(url);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }
    return list;
  }

  async validatePhone(key, phone, phoneIndex) {
    let list = null;
    const errorText = `Could not validate ${this.model}`;
    try {
      let url = `/${this.endpoint}/validate-phone/${key}/${phone}/${phoneIndex}`;
      list = await axios.get(url);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }
    return list;
  }
}

export default MaximizerAbApi;
