<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-form v-model="valid" ref="profileForm">
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model.trim="abEntry.FirstName"
            :rules="validationRulesNotEmpty"
            outlined
            hide-details
            required
          >
            <template slot="label">
              {{ $t("registration.registrationForm.firstName") }}<RedAsterisk></RedAsterisk>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="abEntry.LastName"
            :rules="validationRulesNotEmpty"
            outlined
            hide-details
            required
          >
            <template slot="label">
              {{ $t("registration.registrationForm.lastName") }}<RedAsterisk></RedAsterisk>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="abEntry.Email"
            ref="emailField"
            type="email"
            :rules="validationRulesEmail"
            outlined
            hide-details
            required
          >
            <template slot="label">
              {{ $t("registration.registrationForm.email") }}<RedAsterisk></RedAsterisk>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="abEntry.CompanyName"
            :rules="validationRulesNotEmpty"
            required
            outlined
            hide-details
          >
            <template #label>
              {{ $t("registration.registrationForm.organization") }}<RedAsterisk></RedAsterisk>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="abEntry.Phone1"
            :label="$t('registration.registrationForm.phone')"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="abEntry['Udf/$TYPEID(19)']"
            :label="$t('registration.registrationForm.languagesSpoken')"
            :items="languageSpokenDDL"
            item-text="Value"
            item-value="Key"
            outlined
            hide-details
            multiple
            chips
            deletable-chips
            small-chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="abEntry.Address.AddressLine1"
            :label="$t('registration.registrationForm.address1')"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="abEntry.Address.AddressLine2"
            :label="$t('registration.registrationForm.address2')"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="abEntry.Address.City"
            :label="$t('registration.registrationForm.cityTown')"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="abEntry.Address.StateProvince"
            :label="$t('registration.registrationForm.stateProvince')"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="abEntry.Address.ZipCode"
            :label="$t('registration.registrationForm.zipPostal')"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="abEntry.Address.Country"
            :label="$t('registration.registrationForm.country')"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="abEntry.password"
            :rules="validationRulesPassword"
            :type="passwordHidden ? 'password' : 'text'"
            placeholder=" "
            autocomplete="new-password"
            :append-icon="passwordHidden ? 'visibility' : 'visibility_off'"
            @click:append="() => (passwordHidden = !passwordHidden)"
            :hint="$t('registration.registrationForm.passwordHint')"
            persistent-hint
            outlined
          >
            <template slot="label">
              {{ $t("registration.registrationForm.password")
              }}<RedAsterisk></RedAsterisk> </template
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="secondPassword"
            :type="secondPasswordHidden ? 'password' : 'text'"
            :rules="secondPasswordRules"
            placeholder=" "
            autocomplete="new-password"
            :append-icon="secondPasswordHidden ? 'visibility' : 'visibility_off'"
            @click:append="() => (secondPasswordHidden = !secondPasswordHidden)"
            required
            outlined
            validate-on-blur
          >
            <template slot="label"
              >{{ $t("registration.registrationForm.confirmPassword") }}<RedAsterisk></RedAsterisk>
            </template>
          </v-text-field>
        </v-col>
        <v-col sm="12" lg="6" class="text-center text-h5">
          <v-btn id="cancel-btn" @click="resetAbEntry" color="primary" large block text>
            {{ $t("registration.registrationForm.button.cancel") }}
          </v-btn>
        </v-col>
        <v-col sm="12" lg="6" class="text-center text-h5">
          <v-btn
            class="white--text"
            id="register-btn"
            @click="register"
            color="green"
            large
            block
            :loading="isRegistering"
            >{{ $t("registration.registrationForm.button.register") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Bus from "@/bus";
import RedAsterisk from "@/components/Layout/RedAsterisk";
import MaximizerSchemaApi from "@/api/MaximizerSchemaApi";
import UserApi from "@/api/UserApi";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  components: {
    RedAsterisk,
  },
  data: () => ({
    valid: false,
    isRegistering: false,
    abEntry: { Address: {} },
    passwordHidden: true,
    secondPassword: "",
    secondPasswordHidden: true,
    maximizerSchemaApi: new MaximizerSchemaApi(),
    userApi: new UserApi(),
    languageSpokenDDL: [],
  }),
  created() {
    this.initialize();
  },
  computed: {
    secondPasswordRules(v) {
      return [
        (v) => !!v || this.$t("registration.registrationForm.rule.secondPassword.required"),
        (v) =>
          this.abEntry.password == v ||
          this.$t("registration.registrationForm.rule.secondPassword.passwordDoesNotMatch"),
      ];
    },
  },
  methods: {
    async initialize() {
      this.getSpokenLanguangeDDL();
    },
    async getSpokenLanguangeDDL() {
      const nameData = { name: "Language spoken" };
      const result = await this.maximizerSchemaApi.searchByName(nameData);
      if (result.error) {
        Bus.$emit("alert", "error", result.error);
        return;
      }
      this.languageSpokenDDL = result[0].Items;
    },
    resetAbEntry() {
      this.abEntry = { Address: {} };
      this.$emit("cancel");
    },
    async register() {
      if (!this.$refs.profileForm.validate()) return;

      this.isRegistering = true;
      const result = await this.userApi.registerNewMember(this.abEntry);
      this.isRegistering = false;

      if (result.error) {
        Bus.$emit("alert", "error", result.error);
      } else if (result.abEntryId) {
        Bus.$emit(
          "alert",
          "success",
          this.$t("registration.registrationForm.successMessage.registered")
        );
        this.$emit("registered");
      } else {
        Bus.$emit("alert", "error", "");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#register-btn {
  color: white;
  font-size: 1.5rem;
  height: 4rem !important;
}
#cancel-btn {
  font-size: 1.2rem;
  height: 4rem !important;
}
</style>
