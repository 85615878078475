<template>
  <v-container v-if="abEntry !== null">
    <v-row class="text-center">
      <v-col cols="12" class="text-h3">{{ $t("registration.registrationForm.title") }}</v-col>
    </v-row>
    <v-form ref="registrationForm" v-model="valid">
      <v-row :style="{ paddingTop: '1rem' }">
        <v-col cols="12">
          <v-text-field
            :value="abEntry.FirstName + ' ' + abEntry.LastName"
            :label="$t('registration.registrationForm.name')"
            filled
            disabled
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row :style="{ paddingTop: '1rem' }">
        <v-col cols="12">
          <v-text-field
            :value="abEntry.CompanyName"
            :label="$t('registration.registrationForm.organization')"
            filled
            disabled
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row :style="{ paddingTop: '1rem' }">
        <v-col cols="12">
          <v-text-field
            class="long-input"
            :value="abEntryAddress"
            :label="$t('registration.registrationForm.address')"
            filled
            disabled
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row :style="{ paddingTop: '1rem' }">
        <v-col sm="12" lg="6">
          <v-text-field
            v-model.trim="phone"
            class="long-input"
            :hint="$t('registration.registrationForm.emailHint')"
            persistent-hint
            required
            outlined
          >
            <template slot="label">
              {{ $t("registration.registrationForm.phone") }}<RedAsterisk></RedAsterisk>
            </template>
          </v-text-field>
        </v-col>
        <v-col sm="12" lg="6">
          <v-text-field
            v-model.trim="email"
            ref="emailField"
            class="long-input"
            type="email"
            :rules="validationRulesEmail"
            placeholder=" "
            required
            outlined
          >
            <template slot="label">
              {{ $t("registration.registrationForm.email") }}<RedAsterisk></RedAsterisk>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row :style="{ paddingTop: '1rem' }">
        <v-col>
          <v-text-field
            v-model="password"
            :rules="validationRulesPassword"
            :type="passwordHidden ? 'password' : 'text'"
            placeholder=" "
            :hint="$t('registration.registrationForm.passwordHint')"
            :append-icon="passwordHidden ? 'visibility' : 'visibility_off'"
            @click:append="() => (passwordHidden = !passwordHidden)"
            persistent-hint
            outlined
            ><template slot="label"
              >{{ $t("registration.registrationForm.password")
              }}<RedAsterisk></RedAsterisk> </template
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.trim="secondPassword"
            :type="secondPasswordHidden ? 'password' : 'text'"
            :rules="secondPasswordRules"
            placeholder=" "
            :append-icon="secondPasswordHidden ? 'visibility' : 'visibility_off'"
            @click:append="() => (secondPasswordHidden = !secondPasswordHidden)"
            required
            outlined
            validate-on-blur
          >
            <template slot="label"
              >{{ $t("registration.registrationForm.confirmPassword") }}<RedAsterisk></RedAsterisk>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row :style="{ paddingTop: '1rem' }">
      <v-col sm="12" lg="6" class="text-center text-h5">
        <v-btn id="cancel-btn" @click="resetAbEntry" color="primary" large block text>
          {{ $t("registration.registrationForm.button.cancel") }}
        </v-btn>
      </v-col>
      <v-col sm="12" lg="6" class="text-center text-h5">
        <v-btn
          id="register-btn"
          @click="registerAbEntry"
          color="green"
          large
          block
          :disabled="isRegistering"
          :loading="isRegistering"
          >{{ $t("registration.registrationForm.button.yesRegister") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  <Spinner v-else ref="spinner"></Spinner>
</template>

<script>
import Bus from "@/bus";
import Spinner from "@/components/Layout/Spinner";
import RedAsterisk from "@/components/Layout/RedAsterisk";
import MaximizerAbApi from "@/api/MaximizerAbApi";
import UserApi from "@/api/UserApi";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  components: {
    Spinner,
    RedAsterisk,
  },
  data: () => ({
    valid: false,
    abEntry: null,
    email: null,
    phone: null,
    isRegistering: false,
    maximizerAbApi: new MaximizerAbApi(),
    userApi: new UserApi(),
    password: "",
    passwordHidden: true,
    secondPassword: "",
    secondPasswordHidden: true,
  }),
  created() {
    this.initialize();
  },
  computed: {
    abEntryAddress() {
      if (this.abEntry == null) return "";
      const address = this.abEntry.Address;
      return `${address.AddressLine1}, ${address.City}, ${address.StateProvince} ${address.ZipCode}, ${address.Country}`;
    },
    secondPasswordRules(v) {
      return [
        (v) => !!v || this.$t("registration.registrationForm.rule.secondPassword.required"),
        (v) =>
          this.password == v ||
          this.$t("registration.registrationForm.rule.secondPassword.passwordDoesNotMatch"),
      ];
    },
  },
  methods: {
    async initialize() {
      // Make sure $refs are available
      await this.$nextTick();
      this.$refs.spinner.on();
    },
    async loadAbEntry(abEntry) {
      this.abEntry = abEntry;
      this.email = abEntry.Email;
      this.phone = abEntry.Phone;
      await this.$nextTick();
      this.$refs.emailField.focus();
    },
    resetAbEntry() {
      this.abEntry = null;
      this.$emit("cancel");
    },
    async registerAbEntry() {
      if (!this.$refs.registrationForm.validate()) return;

      this.isRegistering = true;
      const result = await this.userApi.registerMember({
        abEntryKey: this.abEntry.Key.Value,
        email: this.email.toLowerCase(),
        phone: this.phone,
        password: this.password,
      });
      this.isRegistering = false;

      if (result.error) {
        Bus.$emit("alert", "error", result.error);
      } else if (result.abEntryId) {
        Bus.$emit(
          "alert",
          "success",
          this.$t("registration.registrationForm.successMessage.registered")
        );
        this.$emit("registered");
      } else {
        Bus.$emit("alert", "error", "");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$font-color: #444444;
.text-h3 {
  color: $font-color;
}
::v-deep .v-text-field--filled {
  .v-input__slot {
    min-height: 5.5rem;
  }
  label {
    color: $font-color;
    font-size: 1.5rem;
    min-height: 2rem;
  }
  input {
    min-height: 4.5rem;
    margin-top: 1rem;
    font-size: 2rem;
    color: $font-color;
  }
}
::v-deep .v-text-field--outlined {
  .v-input__slot {
    min-height: 5.5rem;
  }
  label {
    color: $font-color;
    font-size: 1.5rem;
    min-height: 2rem;
  }
  input {
    min-height: 4.5rem;
    margin-top: 1rem;
    font-size: 2rem;
    color: $font-color;
  }
}
::v-deep .v-text-field.long-input {
  input {
    font-size: 1.5rem;
  }
}
::v-deep .v-text-field__details .v-messages__message {
  line-height: 1.2rem;
  font-size: 1rem;
}
#register-btn {
  color: white;
  font-size: 1.5rem;
  height: 4rem !important;
}
#cancel-btn {
  font-size: 1.2rem;
  height: 4rem !important;
}
</style>
