<template>
  <v-container class="pa-0">
    <v-row>
      <v-col class="text-center">
        <div class="text-h4">
          {{ $t("registration.registrationSuccessful.title") }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <div class="text-h6">
          <span v-html="$t('registration.registrationSuccessful.instructions')"></span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn id="signin-btn" color="primary" large block text @click="$router.push('/signin')">
          {{ $t("registration.registrationSuccessful.signinRedirect") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss" scoped>
#signin-btn {
  font-size: 1.2rem;
  height: 4rem !important;
}
</style>
