<template>
  <v-container class="content-container pa-0">
    <v-row>
      <v-spacer></v-spacer>
      <v-col md="12" cols="10" class="pt-0">
        <AddressBookSearch
          v-if="activeComponent == 'search'"
          @entry-confirmed="loadRegistrationForm"
          @register-new="loadNewUserRegistrationForm"
        ></AddressBookSearch>
        <RegistrationForm
          v-else-if="activeComponent == 'registration'"
          ref="registrationForm"
          @cancel="activeComponent = 'search'"
          @registered="activeComponent = 'registrationSuccessful'"
        ></RegistrationForm>
        <NewUserRegistrationForm
          v-else-if="activeComponent == 'newRegistration'"
          ref="newUserRegistrationForm"
          @cancel="activeComponent = 'search'"
          @registered="activeComponent = 'registrationSuccessful'"
        ></NewUserRegistrationForm>
        <RegistrationSuccessful
          class="mt-8"
          v-else-if="activeComponent == 'registrationSuccessful'"
        ></RegistrationSuccessful>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import Bus from "@/bus";
import AddressBookSearch from "@/components/Registration/AddressBookSearch";
import RegistrationForm from "@/components/Registration/RegistrationForm";
import NewUserRegistrationForm from "@/components/Registration/NewUserRegistrationForm.vue";
import RegistrationSuccessful from "@/components/Registration/RegistrationSuccessful.vue";

export default {
  components: {
    AddressBookSearch,
    RegistrationForm,
    NewUserRegistrationForm,
    RegistrationSuccessful,
  },
  data: () => ({
    activeComponent: "search",
  }),
  methods: {
    async loadRegistrationForm(abEntry) {
      // Handle users that have already registered
      if (abEntry.IsRegistered === true) {
        Bus.$emit("alert", "info", this.$t("registration.alreadyRegisteredMessage"));
        this.$router.push({ name: "signin", params: { email: abEntry.Email } });
        return;
      }

      this.activeComponent = "registration";
      // Make sure $refs are available
      await this.$nextTick();
      this.$refs.registrationForm.loadAbEntry(abEntry);
    },
    async loadNewUserRegistrationForm() {
      this.activeComponent = "newRegistration";
    },
  },
};
</script>
