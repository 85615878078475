<template>
  <v-container class="pa-0">
    <v-row>
      <v-col class="text-center">
        <div class="text-h4">
          {{ $t("registration.title") }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <div class="text-subtitle-1">
          <span v-html="$t('registration.message')"></span>
        </div>
      </v-col>
    </v-row>
    <v-row fill-height class="mt-3">
      <v-col cols="12" md="5">
        <v-text-field
          :label="$t('registration.addressBookSearch.firstName')"
          v-model.trim="searchQuery.firstName"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="5">
        <v-text-field
          :label="$t('registration.addressBookSearch.lastName')"
          v-model.trim="searchQuery.lastName"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn
          color="success"
          @click="searchAddressBook"
          block
          height="100%"
          min-height="50px"
          :disabled="isSearching"
          :loading="isSearching"
          large
          >{{ $t("registration.addressBookSearch.button.search") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div v-if="entriesNotFound" class="text-center red--text">
          {{ $t("registration.addressBookSearch.errorMessage.entriesNotFound") }}
          <v-btn text color="primary" @click="$emit('register-new')">{{
            $t("registration.addressBookSearch.button.pleaseRegister")
          }}</v-btn>
        </div>
        <div v-else-if="searchEmptyError" class="text-center red--text">
          {{ $t("registration.addressBookSearch.errorMessage.searchEmpty") }}
        </div>
        <div v-else-if="isMultipleEntires" class="text-center orange--text text--darken-2">
          {{ $t("registration.addressBookSearch.errorMessage.duplicateEntries") }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-list>
          <template v-for="(item, index) in searchEntries">
            <v-list-item :key="item.Key" class="px-0">
              <template>
                <v-list-item-content>
                  <v-row>
                    <v-col cols="3" class="d-flex justify-left align-center">
                      <v-list-item-title
                        v-text="item.FirstName + ' ' + item.LastName"
                      ></v-list-item-title>
                    </v-col>
                    <v-col cols="6" class="px-0 d-flex justify-center align-center">
                      <span v-text="item.PhoneFirstDigits"></span>
                      <div class="number-entry-wrap">
                        <input
                          :ref="'item-' + item.Key + '-first-digit-input'"
                          v-model="item.PhoneMissingDigits[0]"
                          class="single-digit-input"
                          @input="enterPhoneDigit(item, $event, 0)"
                        />
                        <input
                          v-model="item.PhoneMissingDigits[1]"
                          class="single-digit-input"
                          @input="enterPhoneDigit(item, $event, 1)"
                          @keyup.delete="erasePhoneDigit(item, $event, 1)"
                        />
                        <input
                          v-model="item.PhoneMissingDigits[2]"
                          class="single-digit-input"
                          @input="enterPhoneDigit(item, $event, 2)"
                          @keyup.delete="erasePhoneDigit(item, $event, 2)"
                        />
                        <input
                          v-model="item.PhoneMissingDigits[3]"
                          class="single-digit-input"
                          @input="enterPhoneDigit(item, $event, 3)"
                          @keyup.delete="erasePhoneDigit(item, $event, 3)"
                        />
                      </div>
                    </v-col>
                    <v-col cols="3" class="d-flex justify-end align-start">
                      <v-btn
                        color="green"
                        class="white--text"
                        @click="validateSearchEntry(item)"
                        :disabled="isValidatingPhone"
                        :loading="isValidatingPhone"
                        >{{ $t("registration.addressBookSearch.button.confirm") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.IsPhoneValidationEmpty || item.IsPhoneValidationFailed">
                    <v-spacer></v-spacer>
                    <v-col cols="6" class="px-0 d-flex justify-center">
                      <div
                        class="red--text text-error-validation"
                        v-show="item.IsPhoneValidationEmpty"
                      >
                        {{ $t("registration.addressBookSearch.errorMessage.phoneValidationEmpty") }}
                      </div>
                      <div
                        class="red--text text-error-validation"
                        v-show="item.IsPhoneValidationFailed"
                      >
                        {{
                          $t("registration.addressBookSearch.errorMessage.phoneValidationFailed")
                        }}
                      </div>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div>
                        {{ $t("registration.addressBookSearch.enterPhoneNumberMessage") }}
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-divider v-if="index < searchEntries.length - 1" :key="index"></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Bus from "@/bus";
import MaximizerAbApi from "@/api/MaximizerAbApi";

export default {
  components: {},
  data: () => ({
    isSearching: false,
    isValidatingPhone: false,
    entriesNotFound: false,
    searchEmptyError: false,
    isMultipleEntires: false,
    searchQuery: {
      firstName: "",
      lastName: "",
    },
    searchEntries: [],
    maximizerAbApi: new MaximizerAbApi(),
  }),
  computed: {
    isSearchValid() {
      return this.searchQuery.lastName !== "";
    },
  },
  methods: {
    async validateSearchEntry(item) {
      if (item.PhoneMissingDigits.some((x) => x === "")) {
        item.IsPhoneValidationEmpty = true;
        item.IsPhoneValidationFailed = false;
        return;
      }

      item.IsPhoneValidationEmpty = false;
      let phoneNumber = item.PhoneFirstDigits.concat(item.PhoneMissingDigits.join("")).replace(
        /\s/g,
        ""
      );

      if (phoneNumber) {
        this.isValidatingPhone = true;
        const result = await this.maximizerAbApi.validatePhone(
          item.Key,
          phoneNumber,
          item.PhoneIndex
        );
        this.isValidatingPhone = false;

        if (result.error) {
          Bus.$emit("alert", "error", result.error);
          return;
        } else if (result == false) {
          item.IsPhoneValidationFailed = true;
          item.PhoneMissingDigits = Array(4).fill("");
          // Set focus back to the first digit input
          this.$refs[`item-${item.Key}-first-digit-input`][0].focus();
          return;
        } else if (result.Key) {
          this.resetEntry();
          this.$emit("entry-confirmed", result);
        }
      }
    },
    async searchAddressBook() {
      if (!this.isSearchValid) {
        this.searchEmptyError = true;
        this.entriesNotFound = false;
        this.isMultipleEntires = false;
        return;
      }

      this.searchEmptyError = false;
      this.isSearching = true;
      const result = await this.maximizerAbApi.search(this.searchQuery);
      this.isSearching = false;

      result.forEach((el) => {
        el.IsPhoneValidationEmpty = false;
        el.IsPhoneValidationFailed = false;
        el.IsPhoneCorrect = false;
        el.PhoneMissingDigits = Array(4).fill("");
      });

      if (result.error) {
        Bus.$emit("alert", "error", result.error);
        this.searchEntries = [];
        return;
      }

      this.entriesNotFound = result.length <= 0;
      this.isMultipleEntires = result.length > 1;
      this.searchEntries = result;

      await this.$nextTick();
      const firstItemKey = this.searchEntries[0].Key;
      // Set focus on the first item first digit input
      this.$refs[`item-${firstItemKey}-first-digit-input`][0].focus();
    },
    resetEntry() {
      this.searchQuery.firstName = "";
      this.searchQuery.lastName = "";
      this.searchEntries = [];
    },
    enterPhoneDigit(item, event, position) {
      const trimmedDigit = event.target.value.trim().replace(/[^\d]/g, "").substring(0, 1);
      item.PhoneMissingDigits[position] = trimmedDigit;

      if (trimmedDigit === "") return;
      if (position < 3) event.target.nextElementSibling.focus();
    },
    erasePhoneDigit(item, event, position, digit) {
      item.PhoneMissingDigits[position - 1] = "";
      // Update UI binding
      const searchItemIndex = this.searchEntries.findIndex((x) => x.Key === item.Key);
      this.$set(this.searchEntries, searchItemIndex, item);
      event.target.previousElementSibling.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
$font-color: #444444;
.number-entry-wrap {
  display: inline-block;
}
.single-digit-input {
  &:first-child {
    margin-left: 10px;
  }
  &:last-child {
    margin-right: 10px;
  }
  &:focus {
    outline: none;
  }
  display: inline-block;
  margin: 0 5px 0 5px;
  width: 35px;
  height: 45px;
  border: 2px solid #bbbbbb;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: $font-color;
}
</style>
